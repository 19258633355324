
header.navbar[data-v-55d8a8ba] {
  background: #1b1c1d;
  color: rgba(255,255,255,0.9);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
}
header.navbar .navbar-item[data-v-55d8a8ba] {
  padding: 13px 16px;
  position: relative;
}
header.navbar .navbar-item[data-v-55d8a8ba]:after,
header.navbar .navbar-item[data-v-55d8a8ba]:before {
  background: rgba(255,255,255,0.08);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 1px;
}
header.navbar .navbar-item[data-v-55d8a8ba]:after {
  right: 0;
}
header.navbar .navbar-item[data-v-55d8a8ba]:before {
  left: 0;
}
header.navbar .logo[data-v-55d8a8ba] {
  align-items: center;
  display: flex;
  color: rgba(255,255,255,0.9);
  font-weight: 700;
  text-decoration: none;
}
header.navbar .logo .logo-img[data-v-55d8a8ba] {
  height: 18px;
  width: 35px;
}
header.navbar .nav-group[data-v-55d8a8ba] {
  display: flex;
}
.clickable[data-v-55d8a8ba] {
  cursor: pointer;
}
