
.button[data-v-2f6b1f2b] {
  background-color: #4dbfbf;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  min-width: 64px;
  padding: 8px 22px;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
}
.button[data-v-2f6b1f2b]:not(:disabled):hover {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  background-color: #d5d5d5;
}
.button[data-v-2f6b1f2b]:disabled {
  background-color: rgba(0,0,0,0.12);
  cursor: default;
  color: rgba(0,0,0,0.26);
}
