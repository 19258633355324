
.home-content[data-v-954084b0] {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  justify-content: center;
  padding: 24px;
  width: 70%;
  margin: auto;
}
.icon-holder[data-v-954084b0] {
  display: flex;
  justify-content: center;
}
.cardHover[data-v-954084b0] {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
.cardHover[data-v-954084b0]:hover {
  top: -5px;
}
.clickable[data-v-954084b0] {
  cursor: pointer;
}
