
.login[data-v-079e7774] {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.login-wrapper[data-v-079e7774] {
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  max-width: 372px;
  width: 100%;
}
.login-title[data-v-079e7774] {
  color: #333;
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  padding: 16px;
  text-align: center;
}
.login-form[data-v-079e7774] {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 8px;
}
.login-button[data-v-079e7774] {
  align-items: center;
  display: flex;
  padding-top: 42px;
}
