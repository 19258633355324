
html,
body,
button,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: #000;
  background: #fff;
}
ul,
ol {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
a img {
  border: none;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
html,
body,
main {
  height: 100%;
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
}
.container {
  height: calc(100% - 44px);
}
.container .main {
  padding-top: 44px;
}
