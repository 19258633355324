
div.text-input[data-v-bd65834e] {
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 12px;
  position: relative;
  width: 100%;
}
div.text-input label[data-v-bd65834e] {
  color: rgba(0,0,0,0.54);
  left: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  text-transform: capitalize;
  top: 24px;
  transition: 0.2s ease all;
}
div.text-input input[data-v-bd65834e] {
  border: none;
  border-bottom: 2px solid #949494;
  font-family: inherit;
  font-size: 14px;
  line-height: 17px;
  margin-top: 16px;
  outline: none;
  padding: 6px 0 7px;
  width: 100%;
}
div.text-input input:focus + label[data-v-bd65834e],
div.text-input input:valid + label[data-v-bd65834e] {
  font-size: 11px;
  top: 0;
}
div.text-input .error-message[data-v-bd65834e] {
  color: #f44336;
  font-size: 11px;
  margin-top: 3px;
}
div.text-input.error label[data-v-bd65834e],
div.text-input.error input[data-v-bd65834e] {
  color: #f44336;
}
div.text-input.error input[data-v-bd65834e] {
  border-bottom-color: #f44336;
}
